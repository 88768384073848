import axios from "./api"

export const getClientStatus = (value, method="id" ) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/status?${method}=${value}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getClientUuid = (uuid, loanId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/loanId/${loanId}/uuid/${uuid}/iv/data`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const getAlternativeImages = (loanId, sessionId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/loan/${loanId}/session/${sessionId}/iv/imagesvideo`)
      .then(response => resolve(response.status))
      .catch(error => reject(error))
  })
}

export const sendLastDesicion = payload => {
  return new Promise((resolve, reject) => {
    axios
      .post("/client/iv/decisionfinal", payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const postPending = (pageNumber, pageSize, order, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/iv/pending?pageNumber=${pageNumber}&pageSize=${pageSize}&order=${order}`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

// https://jta.testing.vertical.com.co/jta/api/client/uuid/{aca va el uuid del cliente}
/**
 * @typedef {{
  cc: string
  givenname: string
  surname: string
  lastname: string
  phone: {
    id: number
    value: string
  },
  email: {
    id: number
    value: string
  }
}} client
 */

/**
 * @type {(payload: client) => Promise}
 */
export const createClient = payload => {
  return new Promise((resolve, reject) => {
    axios
      .post("/client/", payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @typedef {{
  economicActivity: number
  jobType: number
  monthlySalary: number
  paymentMethod: number
  economicSector: number
  company: string
  nit: string
  paymentPeriodicity: number
  otherIncome: number
}} laboralInformation
 */

/**
 * @type {(documentNumber: string, payload: laboralInformation) => Promise}
 */
export const addLaboralInformation = (documentNumber, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${documentNumber}/laboralInformation`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(phone: string) => Promise}
 */
export const sendOTP = phone => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${phone}/otp`, {})
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(phone: string, code: string) => Promise}
 */
export const validateOTP = (phone, code) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${phone}/otp/${code}`, {})
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @typedef {{
  email: string,
  expeditionDate: string,
  birthdate: string,
  departmentCode: string,
  bank: number,
  genderId: number,
  dependents: string,
  educationalLevel: number,
  maritalStatus: number,
  housingType: number,
  stayTime: number,
  address: string,
  cityCode: string,
  neighborhood: string,
  personalReference: {
    givenname: string,
    surname: string,
    phone: string,
    relationship: number
  },
  familyReference: {
    givenname: string,
    surname: string,
    phone: string,
    relationship: number
  }
}} personalInformation
 */

/**
 * @type {(documentNumber: string, payload: personalInformation) => Promise}
 */
export const addPersonalInformation = (documentNumber, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${documentNumber}/personalInformation`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, payload: {email: string}) => Promise}
 */
export const updatePersonalInformation = (documentNumber, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/client/${documentNumber}/personalInformation`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(loanId: number) => Promise}
 */
export const loanSignatureController = loanId => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/loan/${loanId}/signature`, {})
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(loanId: number) => Promise}
 */
export const loanDesist = loanId => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/loan/${loanId}/status?command=withdrawnByApplicant`, {})
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(loanId: number) => Promise}
 */
export const loanDoneScrapping = loanId => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/loan/${loanId}/status?command=awaitingLoanRequest`, {})
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(loanId: number) => Promise}
 */
export const undoLoanDesist = loanId => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/loan/${loanId}/status?command=undoWithdrawn`, {})
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, loanId: number) => Promise}
 */
export const acceptDisclaimer = (documentNumber, loanId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`client/${documentNumber}/loan/${loanId}/disclaimer`, {})
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, loanId: number) => Promise}
 */
export const changeElectronicIdStatus = (documentNumber, loanId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`client/${documentNumber}/loan/${loanId}/electronicid/status`, {})
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getSelectors = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/selectors/`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getDepartamentCodes = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/selectors/department`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getCitiesByDepartment = departmentId => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/selectors/department/${departmentId}/cities`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, loanId: number) => Promise}
 */
export const getBankAccounts = (documentNumber, loanId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/${documentNumber}/loan/${loanId}/buro/bi`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, loanId: number) => Promise}
 */
export const bankInformation = (documentNumber, loanId, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${documentNumber}/loan/${loanId}/bankInformation`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {
 * costsAndExpenses: boolean,
   aval: boolean,
   platform: boolean,
   promissory: boolean,
   powerIrrevocable: boolean,
   guarantees: boolean
  } => Promise}
 */
export const loanDisclaimerPost = (documentNumber, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${documentNumber}/termOfAgreement`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {
 * disbursementMethodId: number,
  } => Promise}
 */
export const methodIdPost = (loan, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/loan/${loan}/dm`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const videoIDGet = (documentNumber, loanId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/${documentNumber}/loan/${loanId}/iv/status`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type payload { jtaResult: number, jtaNotes: string }
 */
export const predisbursementPost = (documentNumber, loanId, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${documentNumber}/loan/${loanId}/validationsok`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type payload { monthlySalary: number }
 */
 export const bankStatementsPolicy = (documentNumber, loanId, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${documentNumber}/loan/${loanId}/bankstatementspolicy`, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

