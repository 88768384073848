export default {
  status: "",
  registrationAttemps: [],
  personalInformation: null,
  laboralInformation: null,
  loans: [],
  loanActive: null,
  confronta: "TODO",
  confrontaQuestions: [],
  info: null,
  identityValidationAttemps: [],
  ubica: {},
  uuid:{},
  pending: {},
  images: {},
  formSteps: [
    { step: "search", completed: false, order: 0 },
    { step: "desist", completed: false, order: 1 },
    { step: "basicInformation", completed: false, order: 2 },
    { step: "ubica", completed: false, order: 3 },
    { step: "generateOTP", completed: false, order: 4 },
    { step: "personalInformation", completed: false, order: 5 },
    { step: "residentialInformation", completed: false, order: 6 },
    // { step: "references", completed: false, order: 7 },
    { step: "laboralInformation", completed: false, order: 8 },
    // { step: "disclaimer", completed: false, order: 9 },
    { step: "scraping", completed: false, order: 10 },
    { step: "simulator", completed: false, order: 11 },
    { step: "identityValidation", completed: false, order: 12 },
    //{ step: "confronta", completed: false, order: 13 },
    { step: "bankInformation", completed: false, order: 14 },
    //{ step: "disbursementMethod", completed: false, order: 15 },
    { step: "approvedAwaitingSignature", completed: false, order: 16 },
    { step: "pre-disbursement", completed: false, order: 17 }
  ],
  ubicaSelected: null,
  selectors: null,
  departments: [],
  photoProfile: null
}
