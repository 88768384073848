export const setStatus = (state, status) => {
  state.status = status
}

export const removeStatus = state => {
  state.status = ""
}

export const setRegistrationAttemps = (state, registrationAttemps) => {
  state.registrationAttemps = registrationAttemps
}

export const removeRegistrationAttemps = state => {
  state.registrationAttemps = []
}

export const setPersonalInformation = (state, personalInformation) => {
  state.personalInformation = personalInformation
}

export const removePersonalInformation = state => {
  state.personalInformation = null
}

export const setLaboralInformation = (state, laboralInformation) => {
  state.laboralInformation = laboralInformation
}

export const removeLaboralInformation = state => {
  state.laboralInformation = null
}

export const setLoans = (state, loans) => {
  state.loans = loans
}

export const removeLoans = state => {
  state.loans = []
  state.loanActive = null
}
//Para regresar a monto y plazo y tomar el loand en proceso
export const addLoanActive = (state, loand) => {
  state.loanActive = loand
}

export const setConfronta = (state, confronta) => {
  state.confronta = confronta
}

export const removeConfronta = state => {
  state.confronta = "TODO"
}

export const setUuid = (state, uuid) => {
  state.uuid = uuid
}

export const removeUuid = state => {
  state.uuid = {}
}

export const setPending = (state, pending) => {
  state.pending = pending
}

export const removePending = state => {
  state.pending = {}
}

export const setImages = (state, images) => {
  state.images = images
}

export const removeImages = state => {
  state.images = {}
}

export const setInfo = (state, info) => {
  state.info = info
}

export const removeIsnfo = state => {
  state.info = null
}

export const setIdentityValidationAttemps = (
  state,
  identityValidationAttemps
) => {
  state.identityValidationAttemps = identityValidationAttemps
}

export const removeIdentityValidationAttemps = state => {
  state.identityValidationAttemps = []
}

export const setUbica = (state, ubica) => {
  state.ubica = ubica
}

export const removeUbica = state => {
  state.ubica = {}
}

export const completeFormStep = (state, step) => {
  let formSteps = state.formSteps.map(formStep => {
    if (formStep.step === step) {
      formStep.completed = true
    }
    return formStep
  })
  state.formSteps = formSteps
}

export const enabledFormStep = (state, steps) => {
  let formSteps = state.formSteps.map(formStep => {
    if (steps.includes(formStep.step)) {
      formStep.completed = false
    }
    return formStep
  })
  state.formSteps = formSteps
}

export const initialForm = state => {
  state.formSteps = [
    { step: "search", completed: false, order: 0 },
    { step: "desist", completed: false, order: 1 },
    { step: "basicInformation", completed: false, order: 2 },
    { step: "ubica", completed: false, order: 3 },
    { step: "generateOTP", completed: false, order: 4 },
    { step: "personalInformation", completed: false, order: 5 },
    { step: "residentialInformation", completed: false, order: 6 },
    // { step: "references", completed: false, order: 7 },
    { step: "laboralInformation", completed: false, order: 8 },
    // { step: "disclaimer", completed: false, order: 9 },
    { step: "scraping", completed: false, order: 10 },
    { step: "simulator", completed: false, order: 11 },
    { step: "identityValidation", completed: false, order: 12 },
    //{ step: "confronta", completed: false, order: 13 },
    { step: "bankInformation", completed: false, order: 14 },
    //{ step: "disbursementMethod", completed: false, order: 15 },
    { step: "approvedAwaitingSignature", completed: false, order: 16 },
    { step: "pre-disbursement", completed: false, order: 17 }
  ]
}

export const setUbicaSelected = (state, ubicaSelected) => {
  state.ubicaSelected = ubicaSelected
}

export const removeUbicaSelected = state => {
  state.ubicaSelected = null
}

export const setSelectors = (state, selectors) => {
  state.selectors = selectors
}

export const addSelector = (state, newSelector) => {
  state.selectors = {
    ...state.selectors,
    ...newSelector
  }
}

export const removeSelectors = state => {
  state.selectors = null
}

export const setDepartments = (state, departments) => {
  state.departments = departments
}

export const removeDepartments = state => {
  state.departments = null
}

/******************  [preguntas confronta] *************************/

export const setConfrontaQuestions = (state, confrontaQuestions) => {
  state.confrontaQuestions.push(confrontaQuestions)
}

export const removeConfrontaQuestions = state => {
  state.confrontaQuestions = []
}

export const setConfrontaAnswers = (state, confrontaAnswers) => {
  state.confrontaAnswers.push(confrontaAnswers)
}

export const removeConfrontaAnswers = state => {
  state.confrontaAnswers = []
}

export const updateConfrontaQuestion = (state, question) => {
  state.confrontaQuestions.map((data, idx) => {
    if (data.id == question.id) {
      state.confrontaQuestions[idx] = question
    }
  })
}

export const setPhotoProfile = (state, image) => {
  state.photoProfile = image
}

export const removePhotoProfile = state => {
  state.photoProfile = null
}
